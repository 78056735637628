import * as React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useEmblaCarousel } from 'embla-carousel/react'
import Section from 'components/Section'
import Button from 'components/Button'
import RouterLink from 'containers/RouterLink'
import ArrowBackIos from 'components/icons/ArrowBackIos'
import ArrowForwardIos from 'components/icons/ArrowForwardIos'
import IconButton from 'components/IconButton'
import Typography from 'components/Typography'
import CatalogProduct from '../../containers/CatalogProduct'

const BREAKPOINT_KEY_UP = 'md'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    padding: '0px 7px',
    position: 'relative',
    gap: theme.spacing(2),
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0px 23px',
    },
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 1.5, 0, 1.5),
    gap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 1.5, 0, 0),
    },
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      padding: theme.spacing(12, 0, 0, 0),
      gap: theme.spacing(4),
      flex: '0 0 25%',
    },
  },
  rightSide: {
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(4),
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      marginTop: '-44px',
      flex: '0 0 75%',
    },
  },
  emblaRoot: {
    overflow: 'hidden',
    width: '100%',
  },
  emblaWrapper: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  controllers: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(0.5),
    justifySelf: 'flex-end',
    alignSelf: 'flex-end',
  },
  navigationButton: {
    color: '#808080',
    padding: 0,
    margin: 0,
    '& *': {
      height: '18px',
      width: '18px',
    },
  },
  navigationPrev: {
    padding: 0,
  },
  navigationNext: {
    padding: 0,
  },
  cta: {
    [theme.breakpoints.down(BREAKPOINT_KEY_UP)]: {
      ...theme.typography.caption2,
      textTransform: 'uppercase',
      marginTop: '6px',
      fontWeight: 500,
    },
  },
  buttonShop: {
    display: 'flex',
    justifyContent: ({ ctaAlign }) => ctaAlign,
  },
  descriptionContainer: {
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      maxWidth: '356px',
    },
    [theme.breakpoints.down(BREAKPOINT_KEY_UP)]: {
      ...theme.typography.body2,
    },
  },
  imageElement: {
    cursor: 'pointer',
  },
}))

const ProductCatalogItem = (props) => {
  const {
    ctaAlign,
    ctaButtonSize,
    ctaLabel,
    ctaType,
    ctaUrl,
    description,
    items,
    menuLabel,
    resetEmbla,
    setResetEmbla,
    headingVariant = 'h4',
    descriptionVariant = 'body1',
  } = props
  const classes = useStyles(props)

  const options = React.useMemo(() => {
    return { align: 'start', containScroll: 'trimSnaps', slidesToScroll: 1 }
  }, [])

  const [emblaRef, embla] = useEmblaCarousel(options)

  const wrapperRef = React.useRef(null)

  const handleResize = () => {
    if (typeof window === 'undefined' || wrapperRef.current === null) return

    if (window.innerWidth < 960) {
      const width = window.innerWidth - 64
      wrapperRef.current.style.width = `${width}px`
    } else {
      wrapperRef.current.style.width = '100%'
    }
  }

  React.useEffect(() => {
    if (embla && resetEmbla) {
      window.addEventListener('resize', handleResize)
      embla.reInit(options)
      embla.scrollTo(0)
      setResetEmbla(false)
    }
  }, [embla, options, resetEmbla, setResetEmbla])

  let cType = 'contained'
  if (ctaType.includes('outlined')) {
    cType = 'outlined'
  } else if (ctaType.includes('contained')) {
    cType = 'contained'
  } else {
    cType = ctaType
  }

  const handlePrev = React.useCallback(() => embla && embla.scrollPrev(), [embla])
  const handleNext = React.useCallback(() => embla && embla.scrollNext(), [embla])

  React.useEffect(() => {
    if (wrapperRef) {
      window.addEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Section className={classes.root}>
      <div className={classes.leftSide}>
        <Typography component="p" variant={headingVariant} className={classes.heading}>
          {menuLabel}
        </Typography>
        {description && (
          // <Html dangerouslySetInnerHTML={{ __html: description }} />
          <Typography
            component="p"
            variant={descriptionVariant}
            className={classes.descriptionContainer}
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Typography>
        )}
        <div className={classes.buttonShop}>
          <Button
            className={classes.cta}
            size={ctaButtonSize.toLowerCase()}
            variant={cType}
            component={RouterLink}
            href={ctaUrl}
            align={ctaAlign}
            fullWidth={false}
          >
            {ctaLabel}
          </Button>
        </div>
      </div>

      <div className={classes.rightSide}>
        <div className={classes.controllers}>
          <IconButton
            className={clsx(classes.navigationButton, classes.navigationPrev)}
            onClick={handlePrev}
            edge="start"
            aria-label="Previous slide"
          >
            <ArrowBackIos />
          </IconButton>

          <IconButton
            className={clsx(classes.navigationButton, classes.navigationNext)}
            onClick={handleNext}
            edge="end"
            aria-label="Next slide"
          >
            <ArrowForwardIos />
          </IconButton>
        </div>

        <div className={classes.emblaRoot} ref={emblaRef}>
          <div className={classes.emblaWrapper} ref={wrapperRef}>
            {items?.map((item, idx) => {
              return <CatalogProduct key={idx} {...item} resetEmbla={resetEmbla} />
            })}
          </div>
        </div>
      </div>
    </Section>
  )
}

ProductCatalogItem.propTypes = {
  ctaAlign: PropTypes.string,
  ctaButtonSize: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaType: PropTypes.string,
  ctaUrl: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  menuLabel: PropTypes.string,
  resetEmbla: PropTypes.bool,
  setResetEmbla: PropTypes.func,
  headingVariant: PropTypes.string,
  descriptionVariant: PropTypes.string,
}

export default ProductCatalogItem
