import * as React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useEmblaCarousel } from 'embla-carousel/react'
import Section from 'components/Section'
import Button from 'components/Button'
import Typography from 'components/Typography'
import ProductCatalogItem from '../ProductCatalogItem'

const BREAKPOINT_KEY_UP = 'sm'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -1,
    padding: '32px 9px 64px 9px',
    borderTop: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
  emblaRoot: {
    zIndex: 2,
    paddingLeft: '21px',
    width: '100%',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      paddingLeft: '23px',
      width: 'auto',
    },
    overflow: 'hidden',
  },
  emblaWrapper: {
    display: 'flex',
    alignContent: 'center',
  },
  emblaSlide: {
    position: 'relative',
    flex: '0 0 auto',
    flexShrink: 0,
  },
  button: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: 0,
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      paddingRight: theme.spacing(6),
    },
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down(BREAKPOINT_KEY_UP)]: {
      ...theme.typography.caption2,
      textTransform: 'uppercase',
      fontWeight: 500,
    },
  },
  buttonSelected: {
    textDecoration: 'underline !important',
  },
  heading: {
    padding: '0px 21px',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      padding: '0px 23px',
    },
    [theme.breakpoints.down(BREAKPOINT_KEY_UP)]: {
      ...theme.typography.h6,
      // letterSpacing: '0.08em',
    },
  },
  rootOneItem: {
    padding: '32px 9px 64px 9px',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      padding: '64px 9px',
    },
  },
}))

const ProductCatalog = (props) => {
  const { catalogItems, heading, headingVariant } = props
  const classes = useStyles(props)
  const [selectedItem, setSelectedItem] = React.useState(catalogItems?.[0])
  const [resetEmbla, setResetEmbla] = React.useState(false)

  // eslint-disable-next-line no-unused-vars
  const [emblaRef, embla] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
  })

  const handleItemChange = React.useCallback(
    (item) => {
      if (!embla) return
      setSelectedItem(item)
      setResetEmbla(true)
    },
    [embla],
  )

  return (
    <Section
      className={clsx(classes.root, {
        [classes.rootOneItem]: catalogItems?.length === 1,
      })}
    >
      <Typography variant={headingVariant} className={classes.heading}>
        {heading}
      </Typography>
      <div className={classes.emblaRoot} ref={emblaRef}>
        <div className={classes.emblaWrapper}>
          {catalogItems?.length > 1 &&
            catalogItems?.map((item, idx) => (
              <div key={idx} className={classes.emblaSlide}>
                <Button
                  variant="text"
                  className={clsx(classes.button, {
                    [classes.buttonSelected]: item === selectedItem,
                  })}
                  onClick={() => handleItemChange(item)}
                >
                  {item.menuLabel || ''}
                </Button>
              </div>
            ))}
        </div>
      </div>

      <ProductCatalogItem {...selectedItem} {...{ resetEmbla, setResetEmbla }} />
    </Section>
  )
}

ProductCatalog.propTypes = {
  catalogItems: PropTypes.arrayOf(PropTypes.object),
  heading: PropTypes.string,
  headingVariant: PropTypes.string,
}

export default ProductCatalog
