import * as React from 'react'
import PropTypes from 'prop-types'
import ProductCatalogComponent from './ProductCatalog'

const ProductCatalog = (props) => <ProductCatalogComponent {...props} />

ProductCatalog.propTypes = {
  text: PropTypes.string,
}

export default ProductCatalog
