import * as React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import PropTypes from 'prop-types'
import Image from 'next/image'
import { useRouter } from 'next/router'
import Typography from 'components/Typography'
import { useGlobal } from 'api'
import ItemRating from './ItemRating'

const BREAKPOINT_KEY_UP = 'md'

const useStyles = makeStyles((theme) => ({
  emblaSlide: {
    position: 'relative',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    flex: '0 0 221px',
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 294px',
    },
  },
  imageContainer: {
    width: '100%',
    position: 'relative',
    display: 'block',
    height: '294px',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      height: '442px',
    },
  },
  heading: {
    [theme.breakpoints.down(BREAKPOINT_KEY_UP)]: {
      ...theme.typography.h4,
      fontSize: '30px',
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
  },
  lineClamp: {
    margin: theme.spacing(1, 0),
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  attributeLabel: {
    color: theme.palette.common.white,
    width: 156,
    display: 'grid',
    placeItems: 'center',
    padding: theme.spacing(0.75, 0),
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  attributeContainer: {
    position: 'absolute',
    zIndex: 5,
    top: 12,
    left: 12,
  },
}))

const CatalogProduct = (props) => {
  const {
    product,
    resetEmbla,
    headingVariant,
    descriptionVariant,
    priceVariant,
    attributeLabel,
    attributeColor,
  } = props

  const classes = useStyles(props)
  const router = useRouter()
  const { onFormatCurrency } = useGlobal()

  const imageUrl = product?.imagesData?.find((image) => image.tag === 'Hero_R')?.imageUrl

  if (!product) return <></>

  return (
    <div className={classes.emblaSlide}>
      <div className={classes.imageContainer}>
        {imageUrl && (
          <>
            <Image
              src={imageUrl}
              alt={product.custom4 || ''}
              layout="fill"
              objectFit="cover"
              sizes="(max-width: 600px) 100vw, 600px"
              onClick={() => router.push(product.href)}
              className={classes.imageElement}
            />
            {product?.custom9 || (attributeLabel && attributeLabel !== '') ? (
              <div className={classes.attributeContainer}>
                <div
                  className={classes.attributeLabel}
                  style={{
                    backgroundColor: attributeColor || product?.custom9?.split(';')[1] || '#2E2630',
                  }}
                >
                  {attributeLabel || product?.custom9?.split(';')[0] || product?.custom9}
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>

      <div className={classes.headingContainer}>
        <div className={classes.title}>
          <Typography component="p" variant={headingVariant}>
            {product?.name || ''}
          </Typography>
          <Typography component="p" variant={descriptionVariant}>
            {product?.custom4 || ''}
          </Typography>
        </div>

        <Typography component="p" variant={priceVariant}>
          {onFormatCurrency(product?.priceFrom) || ''}
        </Typography>
      </div>

      <p className={classes.lineClamp}>{product?.custom5 || ''}</p>

      {!resetEmbla && <ItemRating product={product} />}
    </div>
  )
}

CatalogProduct.propTypes = {
  product: PropTypes.object,
  resetEmbla: PropTypes.bool,
  headingVariant: PropTypes.string,
  descriptionVariant: PropTypes.string,
  priceVariant: PropTypes.string,
  attributeLabel: PropTypes.string,
  attributeColor: PropTypes.string,
}

export default CatalogProduct
