import * as React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import PropTypes from 'prop-types'
import Rating from '@material-ui/lab/Rating'
import { useRouter } from 'next/router'
import { useReviews } from 'api'
import Link from 'components/Link'
import { useTranslations } from 'containers/Translations/TranslationsContext'

const useStyles = makeStyles((theme) => ({
  ratingsButton: {
    display: 'flex',
    overflow: 'hidden',
  },
  rating: {},
  ratingLabel: {
    ...theme.typography.overline,
    marginLeft: theme.spacing(1),
  },
}))

const ItemRating = (props) => {
  const { product } = props

  const classes = useStyles(props)
  const router = useRouter()

  const { totalScore, totalReviews } = useReviews(product?.custom8 || product?.productCode)

  const t = useTranslations()

  const handleRatingsClick = React.useCallback(() => {
    const reviewsOnPage = document.querySelector('#reviews')

    if (reviewsOnPage) {
      reviewsOnPage.scrollIntoView({ behavior: 'smooth' })
    } else {
      router.push(`${product.href}?reviews`)
    }
  }, [product, router])

  return (
    <Link // eslint-disable-line jsx-a11y/anchor-is-valid
      display="block"
      component="button"
      type="button"
      className={classes.ratingsButton}
      onClick={handleRatingsClick}
    >
      <Rating className={classes.rating} size="small" value={totalScore} readOnly />
      <span className={classes.ratingLabel}>
        {totalReviews} {t('Web.Price.Reviews')}
      </span>
    </Link>
  )
}

ItemRating.propTypes = {
  product: PropTypes.object.isRequired,
}

export default ItemRating
