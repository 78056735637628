import * as React from 'react'
import PropTypes from 'prop-types'
import { useGlobal } from 'api'
// import StoryblokService from 'utils/storyblok-service'
import CatalogProductComponent from './CatalogProduct'

export const transformProps = (propsArg) => {
  const props = { ...propsArg }

  //   if (props.description) {
  //     props.description = StoryblokService?.client?.richTextResolver?.render(props.description)
  //   }

  return props
}

const CatalogProduct = (props) => {
  const { products } = useGlobal()

  const product = products.find((prod) => prod.productCode === props.productCode.trim())

  const transformedProps = {
    ...props,
    product,
  }

  return <CatalogProductComponent {...transformProps(transformedProps)} />
}

CatalogProduct.propTypes = {
  productCode: PropTypes.string,
  description: PropTypes.string,
}

export default CatalogProduct
